import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import '@/assets/css/reset.css'
import '@/assets/css/element-variables.scss'
import '@/assets/css/variables.scss'
import '@/assets/css/common.scss'
import '@/components/index'
import '@/assets/font/iconfont.css'
import '@/assets/font/iconfont.js'

import i18n from '@/common/langs/index.js'
import bus from '@/utils/bus.js'
import Cookie from '@/utils/cookie.js'
import monent from '@/utils/monent.js'
import dispatch from '@/utils/dispatch.js'
import md5 from '@/utils/md5.js'
import api from '@/api/index.js'

import '@/utils/filter.js'

import Element from 'element-ui'
Vue.use(Element, { size: 'medium', zIndex: 3000 })

Vue.config.productionTip = false
Vue.prototype.$bus = bus
Vue.prototype.$cookie = Cookie
Vue.prototype.$api = api
Vue.prototype.$md5 = md5
Vue.prototype.$monent = monent
Vue.prototype.$dispatch = dispatch

window.vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
