/**
 * 所有类型
 */
// 用户中心
export const UPDATE_USERINFO_TYPE = 'UPDATE_USERINFO_TYPE' // 更新用户信息
export const GET_USERINFO_TYPE = 'GET_USERINFO_TYPE' // 获取用户信息
export const UPDATE_USER_SHOPIFYSTORE_TYPE = 'UPDATE_USER_SHOPIFYSTORE_TYPE' // 更新用户绑定shopify状态
export const GET_USER_SHOPIFYSTORE_TYPE = 'GET_USER_SHOPIFYSTORE_TYPE' // 获取用户是否绑定shopify

// 产品中心 Breadcrumb
export const PRODUCT_BREADCRUMBS_ADD_TYPE = 'PRODUCT_BREADCRUMBS_ADD_TYPE' // 用户详情面包屑+新增
export const PRODUCT_BREADCRUMBS_POP_TYPE = 'PRODUCT_BREADCRUMBS_POP_TYPE' // 用户详情面包屑+删除
