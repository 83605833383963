/**
 * 接口请求参数签名
 * 将所有参数名ASCII码从小到大排序
 * 排序字母转大写+盐+md5加密
 */
import md5 from '@/utils/md5.js'

const sign = (params, salt) => {
    let dataStr = paramsSortString(params) + '&key=rappid5669'
    // console.log('sign----dataStr1:', dataStr)
    dataStr = md5.encode(dataStr) || ''
    // console.log('sign----dataStr2:', dataStr.toLocaleUpperCase())
    return dataStr.toLocaleUpperCase() || ''
}

/**
 * 参数排序
 */
const paramsSortString = (params) => {
    const keys = Object.keys(params).sort() || []
    const keyVals = []
    keys.map((key) => {
        keyVals.push(`${key}=${params[key]}`)
    })
    return keyVals.join('&')
}

export default sign
