<template>
    <el-rate class="rap-rate__wrap"
        v-model="newValue"
        :max="max"
        :disabled="disabled"
        :allow-half="allowHalf"
        :low-threshold="lowThreshold"
        :high-threshold="highThreshold"
        :colors="colors"
        :void-color="voidColor"
        :disabled-void-color="disabledVoidColor"
        :icon-classes="iconClasses"
        :void-icon-class="voidIconClass"
        :disabled-void-icon-class="disabledVoidIconClass"
        :show-text="showText"
        :show-score="showScore"
        :text-color="textColor"
        :texts="texts"
        :score-template="scoreTemplate"
        @change="handleChange">
    </el-rate>
</template>

<script>
export default {
    props: {
        value: Number,
        max: {
            type: Number,
            default: 5
        },
        disabled: Boolean,
        allowHalf: {
            type: Boolean,
            default: true
        },
        lowThreshold: {
            type: Number,
            default: 2
        },
        highThreshold: {
            type: Number,
            default: 2
        },
        colors: {
            type: Array,
            default: () => ['#FFC017', '#FFC017', '#FFC017']
        },
        voidColor: {
            type: String,
            default: '#E8E9EB'
        },
        disabledVoidColor: {
            type: String,
            default: '#EFF2F7'
        },
        iconClasses: {
            type: Array,
            default: () => ['iconfont iconrap-icon-star-on', 'iconfont iconrap-icon-star-on', 'iconfont iconrap-icon-star-on']
        },
        voidIconClass: {
            type: String,
            default: 'iconfont iconrap-icon-star-on'
        },
        disabledVoidIconClass: {
            type: String,
            default: 'iconfont iconrap-icon-star-on'
        },
        showText: Boolean,
        showScore: Boolean,
        textColor: {
            type: String,
            default: '#1F2D3D'
        },
        texts: {
            type: Array,
            default: () => ['极差', '失望', '一般', '满意', '惊喜']
        },
        scoreTemplate: {
            type: String,
            default: '{value}'
        }
    },
    data () {
        return {
            newValue: 0
        }
    },
    watch: {
        value: {
            handler (val) {
                this.newValue = val || 0
            },
            immediate: true
        }
    },
    methods: {
        handleChange (val) {
            this.$emit('input', val)
            this.$emit('change', val)
        }
    }
}
</script>
