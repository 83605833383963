<template>
    <div class="pagination-wrap" :class="{'border': border}">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="rPageNum"
            :page-sizes="pageSizes"
            :page-size="rPageSize"
            :layout="layout"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
export default {
    props: {
        layout: {
            type: String,
            default: 'prev, pager, next, jumper'
        },
        pageSizes: {
            type: Array,
            default: () => [20, 50, 100, 500]
        },
        currentPage: Number,
        total: Number,
        pageSize: Number,
        border: Boolean
    },
    data () {
        return {
            rPageSize: this.pageSize,
            rPageNum: this.currentPage
        }
    },
    methods: {
        handleCurrentChange (val) {
            this.rPageNum = val
            this.$emit('change', {
                pageNum: val,
                pageSize: this.rPageSize
            })
        },
        handleSizeChange (val) {
            this.$emit('change', {
                pageNum: this.rPageNum,
                pageSize: val
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.pagination-wrap{
    margin-top: 10px;
    padding: 20px;
    background: white;
    display: flex;
    justify-content: flex-end;
    &.border {
        border: 1px solid #dedede;
    }
}
</style>
