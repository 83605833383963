import Vue from 'vue'
import axios from 'axios'
import Cookies from 'js-cookie'
import qs from 'qs'
import signTool from '@/utils/sign'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const vm = new Vue()
const instance = axios.create({
    timeout: 3000,
    headers: { 'content-type': 'application/json;charset=UTF-8' }
})

instance.defaults.baseURL = ''
instance.defaults.withCredentials = true

// 请求拦截器
axios.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么
        config.headers.userToken = Cookies.get('USER_TOKEN') || 'rap-token'
        config.headers.locale = localStorage.getItem('LANG') || 'en_US'
        let sign = config.data
        if (config.method.toLocaleUpperCase() === 'GET') {
            sign = (config.url.split('?') && config.url.split('?')[1]) || ''
        }
        config.headers.sign = signTool(qs.parse(sign)) || ''
        return config
    },

    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 响应拦截器
axios.interceptors.response.use(
    (response) => {
        // 对响应数据做点什么
        if (response.status === 200) {
            return response.data
        }
        // vm.$cookie.set('token', 'value', { expires: 7, path: '/a' });
        vm.$message.error(response.data && response.data.message)
        return Promise.reject(response.data)
    },
    (error) => {
        // 对响应错误做点什么
        return Promise.reject(error)
    }
)

const post = (url, data) => {
    return axios.post(url, data)
}

const get = (url, data) => {
    const newData = {}
    Object.keys(data).filter(key => {
        if (data[key]) {
            newData[key] = data[key]
        }
    })
    return axios.get(`${url}?${qs.stringify(newData)}`)
}

const put = (url, data) => {
    return axios.put(url, data)
}

const remove = (url, data) => {
    return axios.delete(url, { data })
}

const all = (data) => {
    return axios.all(data)
}

const http = (options) => {
    if (options.methods && options.methods.toUpperCase() === 'POST') {
        return post(options.url, options.data)
    } else if (options.methods && options.methods.toUpperCase() === 'DELETE') {
        return remove(options.url, options.data)
    } else if (options.methods && options.methods.toUpperCase() === 'PUT') {
        return put(options.url, options.data)
    } else if (options.methods && options.methods.toUpperCase() === 'ALL') {
        return all(options.data)
    }
    return get(options.url, options.data)
}

export default {
    http,
    post,
    get,
    all,
    put,
    remove
}
