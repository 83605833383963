<template>
    <div class="logout-popover__wrap">
        <rap-popover
            placement="bottom-end"
            :visibleArrow="false"
            :width="270"
            :offset="0"
            :visible.sync="showPopover"
            trigger="click"
            popper-class="logout">
            <div slot="reference" class="btn avator-name">
                <rap-image class="avator" :src="userInfo && userInfo.header" :defaultImg="defaultImg" :type="2" :lazy="false"></rap-image>
                <span class="nickname">{{ userInfo && userInfo.first_name }}</span>
            </div>
            <div class="logout-wrap">
                <div class="person-info">
                    <rap-image class="avator-img" :src="userInfo && userInfo.header" :defaultImg="defaultImg" :type="2" :lazy="false"></rap-image>
                    <div class="info">
                        <div class="name">{{ userInfo && userInfo.first_name }}</div>
                        <div class="type">Account Type: Distributor</div>
                    </div>
                </div>
                <div class="menu-list">
                    <div :class="['menu-item', { 'sel': menu.name === active }]"
                        v-for="(menu, index) in menus"
                        :key="index"
                        @click="jump(menu)">
                        <span class="label">{{ menu.label }}</span>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
                <div class="logout-btn" @click="logOut">
                    <el-button type="text">Log out</el-button>
                </div>
            </div>
        </rap-popover>
    </div>
</template>

<script>
export default {
    data () {
        return {
            menus: [
                {
                    label: 'Account information',
                    path: '/personal/baseInfo',
                    name: 'baseInfo'
                },
                {
                    label: 'Billing Information',
                    path: ''
                },
                {
                    label: 'Branding',
                    path: ''
                },
                {
                    label: 'Address',
                    path: '/personal/address',
                    name: 'address'
                }
            ],
            defaultImg: require('@/assets/user-avator.svg'),
            showPopover: false
        }
    },
    computed: {
        active () {
            return this.$route.name
        },
        userInfo () {
            return this.$store.state.userCenter.userInfo
        }
    },
    methods: {
        logOut () {
            this.$cookie.remove('USER_TOKEN')
            localStorage.removeItem('USER_INFO')
            localStorage.removeItem('IS_LOGIN')
            this.$store.commit('userCenter/UPDATE_USERINFO_TYPE', null)
            this.$router.push({
                path: '/login'
            })
        },
        // 跳转页面
        jump (menu) {
            if (menu.name !== this.$route.name) {
                this.$router.push({
                    path: menu.path
                })
                this.showPopover = false
            }
        }
    }
}
</script>

<style lang="scss">
.logout-popover__wrap{
    margin-left: 20px;
    .avator{
        display: inline-block;
        width: 24px !important;
        height: 24px !important;
        border-radius: 100%;
        cursor: pointer;
        margin-right: 10px;
        overflow: hidden;
    }
    .avator-name {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        .nickname{
            font-size: 13px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 550;
            color: #1a1a1a;
        }
    }
}
.logout {
    padding: 0 !important;
    .logout-wrap{
        .person-info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 20px 24px 15px 24px;
            .avator-img {
                width: 50px;
                height: 50px;
                margin-right: 13px;
                border-radius: 100%;
                overflow: hidden;
            }
            .name {
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #1a1a1a;
            }
            .type {
                font-size: 12px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #666666;
            }
        }
        .menu-list {
            .menu-item {
                padding: 10px 20px;
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #1a1a1a;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                transition: all .1s;
                &:hover, &.sel{
                    background: #f7f7f7;
                    transition: all .3s;
                }
            }
        }
        .logout-btn {
            padding: 0 24px;
            border-top: 1px solid $borderColor;
            height: 64px;
            line-height: 64px;
            width: 100%;
            transition: all .3s;
            box-sizing: border-box;
            text-align: left;
            cursor: pointer;
            &:hover {
                background: #f7f7f7;
                transition: all .3s;
            }
            &:active {
                background: #f1f1f1;
                transition: all .3s;
            }
            .el-button{
                font-size: 14px;
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
                color: #1a1a1a;
            }
        }
    }
}
</style>
