<template>
    <div id="app">
        <el-scrollbar ref="scrollbar" class="rap-scrollbar">
            <span id="AppAnchorId"></span>
            <layout v-if="!$route.meta.nolayout"></layout>
            <router-view v-else></router-view>
        </el-scrollbar>
    </div>
</template>

<script>
    import Layout from '@/components/layout/layout.vue'
    import qs from 'qs'
    export default {
        components: {
            Layout
        },
        watch: {
            '$route' (nval, oval) {
                const params = qs.parse(window.location.search.replace('?', ''))
                if (nval !== oval && this.$cookie.get('USER_TOKEN') && params && params.code && params.hmac) {
                    this.insertAccessToken(params)
                }
                if (nval !== oval) {
                    document.getElementById('AppAnchorId').scrollIntoView()
                    // 获取个人信息
                    if (!this.$store.state.userCenter.userInfo) {
                        this.$store.dispatch('userCenter/GET_USERINFO_TYPE')
                    }
                    // 是否绑定成功
                    if (!this.$store.state.userCenter.bindShopifyStatus) {
                        this.$store.dispatch('userCenter/GET_USER_SHOPIFYSTORE_TYPE')
                    }
                }
            }
        },
        methods: {
            // shopify店铺认证
            insertAccessToken (params) {
                const shopName = params.shop && params.shop.split('.') && params.shop.split('.')[0]
                this.$api.insertAccessToken({
                    EcsShopAuthDto: {
                        shopName,
                        code: params.code,
                        hmac: params.hmac,
                        timestamp: params.timestamp
                    }
                }).then(res => {
                    if (res.code === 0) {
                        window.location.replace(`${window.location.origin}/${window.location.hash}`)
                    } else {
                        this.$notify({
                            title: res.message || 'Authentication is failed',
                            type: 'error'
                        })
                    }
                })
            }
        },
        mounted () {
            const scrollEl = this.$refs.scrollbar.wrap
            scrollEl.onscroll = (e) => {
                this.$bus.$emit('el-scrollbar-scroll', e)
            }
        }
    }
</script>

<style lang="scss">
* {
    font-family: "Helvetica Neue", Helvetica, Arial, "PingFang SC", "Hiragino Sans GB", "Heiti SC", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif !important;
}
#app {
    font-family: PingFangSC, PingFangSC-Regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
    background: rgba(244, 245, 247, 1.0);
    height: $mainHeight;
    // overflow-y: auto;
    min-width: $mainMinWidth;
}
.rap-scrollbar.el-scrollbar{
    height: 100%;
    width: 100%;
}
.rap-scrollbar .el-scrollbar__wrap{
    overflow-x: hidden;
    overflow-y: auto;
}

.rap-scrollbar > ::-webkit-scrollbar{
    width: 0px !important;
    height: 0px !important;
}

.rap-scrollbar .el-scrollbar__bar.is-vertical {
    z-index: 100;
}
</style>
