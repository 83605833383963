import md5 from 'js-md5'

const defaultSalt = ''

const encode = (value, salt = defaultSalt) => {
    const val = `${value}${salt}`
    // console.log('加密内容:', val)
    return md5(val)
}

export default {
    encode
}
