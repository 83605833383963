<template>
    <div class="header-wrap">
        <div class="main-content-wrap">
            <!-- logo -->
            <rap-logo></rap-logo>
            <!-- 菜单 -->
            <rap-menu :dataList="dataList"></rap-menu>
            <!-- 按钮 -->
            <el-button v-if="!isLogin" type="primary" size="small" round @click="$router.push({ path: '/login' })">Sign in</el-button>
            <div class="btn-groups login" v-else>
                <div class="btn shopfy" @click="doJumpStore">
                    <img src="@/assets/rap/header-shopify-img.svg" alt="">
                </div>
                <div class="btn message">
                    <img src="@/assets/rap/header-msg-img.svg" alt="">
                </div>
                <div class="btn card">
                    <img src="@/assets/rap/header-cart-img.svg" alt="">
                </div>
                <!-- 用户信息 -->
                <rap-logout></rap-logout>
            </div>
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import RapLogo from './logo'
import RapMenu from './menu'
import RapLogout from './logout'

import RapCategoryDrawer from '@/views/rap/product/list/components/category-drawer.vue'
export default {
    components: {
        RapLogo,
        RapMenu,
        RapLogout,
        RapCategoryDrawer
    },
    data () {
        return {
            menuLists: [
                {
                    id: 1,
                    name: 'Our strengths',
                    path: '/home'
                },
                {
                    id: 2,
                    name: 'Operation flow',
                    path: '/home/flow'
                },
                {
                    id: 3,
                    name: 'Help center',
                    path: '/help-center'
                },
                {
                    id: 4,
                    name: 'Contact us',
                    path: '/contact-us',
                    active: 'contactUs'
                }
            ],
            newMenuLists: [
                {
                    id: 1,
                    name: 'Dashboard',
                    path: '/dashboard'
                },
                {
                    id: 2,
                    name: 'Find products',
                    path: '/product/list',
                    subMenus: [],
                    visible: false,
                    component: RapCategoryDrawer
                },
                {
                    id: 3,
                    name: 'Import list',
                    path: '/importList'
                },
                {
                    id: 4,
                    name: 'My products',
                    path: '/myProduct'
                },
                {
                    id: 5,
                    name: 'Orders',
                    path: '',
                    subMenus: [
                        {
                            id: 51,
                            name: 'Shopify',
                            path: '/order/shopify'
                        },
                        {
                            id: 52,
                            name: 'Rapddeals',
                            path: '/order/paynow'
                        },
                        {
                            id: 53,
                            name: 'Batch Import',
                            path: '/order/batch'
                        }
                    ]
                }
            ],
            shopifyStoreUrl: ''
        }
    },
    computed: {
        dataList () {
            return this.isLogin ? this.newMenuLists : this.menuLists
        },
        isLogin () {
            return this.$cookie.get('USER_TOKEN')
        }
    },
    methods: {
        // 请求shopify店铺地址
        reqShopifyStoreUrl () {
            this.$api.getShopifyStoreUrl({}).then(res => {
                if (res.code === 0) {
                    this.shopifyStoreUrl = res.data && res.data.url || ''
                }
            })
        },
        // 跳转
        doJumpStore () {
            if (this.shopifyStoreUrl) {
                window.open(this.shopifyStoreUrl)
            }
        }
    },
    mounted () {
        this.$cookie.get('USER_TOKEN') && this.reqShopifyStoreUrl()
    }
}
</script>

<style lang="scss" scoped>
    .header-wrap{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 99;
        height: calc(#{$headerHeight} - 1px);
        background: white;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #efefef;
        min-width: $mainMinWidth;
        .main-content-wrap{
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .btn-groups{
            margin-left: 20px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            font-size: 14px;
            padding-top: 10px;
            .el-button{
                width: 100px;
            }
        }
        .btn-groups.login {
            .btn {
                display: inline-block;
                width: 22px;
                height: 22px;
                margin-left: 20px;
                cursor: pointer;
                img{
                    display: flex;
                    width: 100%;
                    height: 100%;
                }
                transition: all .1s;
                &:hover, &:active{
                    opacity: 0.9;
                    transform: scale(1.2);
                    transition: all .3s;
                }
            }
        }
    }
</style>
