<template>
    <div class="rap-input__wrap"
        :class="{ 'is-focus': focus }"
        @click="doFocus">
        <div :class="['label-wrap', { 'focus': labelFocus }]" v-if="label">{{ label }}</div>
        <!-- 标签 -->
        <div class="tags-wrap"
            v-if="tagLists && tagLists.length">
            <el-tag v-for="(tag, index) in tagLists"
                :key="index"
                closable
                type="info"
                size="small"
                @close="handleClose(index)">
                {{ tag }}
            </el-tag>
        </div>
        <el-input
            class="input-new-tag"
            v-model="inputVal"
            ref="tagInput"
            :placeholder="placeholder"
            :clearable="clearable"
            @keydown.delete.native="handleInputDelete"
            @keyup.enter.native="handleInputEnter"
            @blur="handleBlur">
        </el-input>
    </div>
</template>

<script>
export default {
    props: {
        value: [String, Number],
        label: String,
        placeholder: String,
        clearable: Boolean,
        max: {
            type: Number,
            default: 5
        },
        disabled: String
    },
    data () {
        return {
            focus: false,
            inputVal: ''
        }
    },
    computed: {
        tagLists () {
            return ((this.value && this.value.split(',')) || []).filter(p => !(!p || p.length === 0))
        },
        labelFocus () {
            return this.focus || this.tagLists.length
        }
    },
    methods: {
        handleInput (val) {
            this.$emit('input', val)
            this.$dispatch(this, 'ElFormItem', 'el.form.change', [this.value])
        },
        handleChange (val) {
            this.$emit('change', val)
        },
        doFocus (val) {
            if (this.disabled) return
            this.focus = !this.focus
            this.doMouseFocus(this.focus ? 'focus' : 'blur')
        },
        handleBlur () {
            this.inputVal = ''
            this.$emit('blur')
            this.$nextTick(() => {
                this.focus = false
            })
        },
        handleClose (index) {
            this.focus = true
            this.doMouseFocus('focus')
            const tags = this.tagLists.filter((p, i) => i !== index)
            this.$emit('input', tags.join(','))
        },
        // 回车键
        handleInputEnter (val) {
            if (this.tagLists.length < this.max) {
                const tags = []
                if (this.value) {
                    tags.push(this.value)
                }
                if (this.inputVal) {
                    tags.push(this.inputVal)
                }
                this.inputVal = ''
                this.$emit('input', tags.join(','))
            }
        },
        // 删除键
        handleInputDelete () {
            if (!this.inputVal || !this.inputVal.length) {
                const tags = [...this.tagLists]
                tags.pop()
                this.$emit('input', tags.join(','))
            }
        },
        // 聚焦 | 失焦
        doMouseFocus (key) {
            this.$nextTick(() => {
                this.$refs.tagInput && this.$refs.tagInput[key]()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.rap-input__wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    &.is-focus {
        border-color: #ff6c23;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .label-wrap {
        position: absolute;
        top: 8px;
        left: 5px;
        height: 20px;
        line-height: 20px;
        z-index: 3;
        padding: 0 3px;
        background: white;
        transition: all .15s ease-in-out;
        color: #1d1d1d;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        &.focus {
            transform: translateY(-22px) scale(0.9);
            transition: all .20s ease-in-out;
        }
    }
    .tags-wrap {
        flex-grow: 1;
        flex-shrink: 0;
        padding: 0 0 0 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        max-width: 80%;
        overflow-x: auto;
        /deep/ .el-tag {
            border-width: 0;
            border-radius: 12px;
            padding: 0 10px;
            transition: all .15s ease-in-out;
            &.el-tag--info .el-tag__close {
                right: 0px;
                background: #c7c7c7;
                color: white;
                transition: all .15s ease-in-out;
                &:hover {
                    right: 0px;
                    background: #FF4D4F;
                    color: white;
                    transition: all .3s ease-in-out;
                }
            }
            &:not(:last-of-type) {
                margin-right: 5px;
            }
        }
    }
    /deep/ .el-input__inner {
        border-width: 0;
    }
}
</style>
