<template>
    <div class="rap-price-input" :class="{'focus': focus}">
        <label class="unit">{{ unit }}</label>
        <el-input-number v-model="inputVal"
            :precision="precision"
            :step="step"
            :min="min"
            :max="max"
            :size="size"
            :placeholder="placeholder"
            @change="handleChange"
            @focus="handleFocus"
            @blur="handleBlur">
        </el-input-number>
    </div>
</template>

<script>
export default {
    props: {
        value: Number,
        precision: Number,
        step: Number,
        disabled: Boolean,
        min: Number,
        max: Number,
        size: String,
        placeholder: String,
        unit: {
            type: String,
            default: 'US$'
        }
    },
    data () {
        return {
            inputVal: this.value,
            focus: false
        }
    },
    methods: {
        handleChange (val) {
            this.$emit('change', val)
        },
        handleFocus () {
            this.focus = true
        },
        handleBlur () {
            this.focus = false
        }
    }
}
</script>

<style lang="scss" scoped>
.rap-price-input {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCDFE6;
    padding: 0 0 0 5px;
    background: white;
    border-radius: 2px;
    overflow: hidden;
    &.focus {
        border-color: $mainPrimary;
        transition: all .3s ease-in-out;
    }
    .unit {
        height: 100%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-grow: 0;
        color: #000000;
        font-size: 13px;
    }
    /deep/ .el-input-number .el-input__inner{
        border-width: 0;
        width: 100%;
    }
}
</style>
