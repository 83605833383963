<template>
    <div class="rap-upload__index">
        <el-upload
            class="avatar-uploader"
            :class="cls"
            :action="action"
            :name="name"
            :data="params"
            :limit="limit"
            :accept="imgAccept"
            :show-file-list="false"
            :disabled="disabled || loading"
            :on-success="handleOnSuccess"
            :on-error="handleOnError"
            :before-upload="doBeforeUpload">
            <section v-loading="loading"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 0.2)">
                <div class="content" v-if="!$slots.reference">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </div>
                <slot v-else name="reference"></slot>
            </section>
        </el-upload>
    </div>
</template>

<script>
export default {
    name: 'rap-upload',
    props: {
        value: String,
        cls: String,
        action: {
            type: String,
            default: `${process.env.VUE_APP_REQUEST_BASE_URL}/thirdcenter/picture/upload`
        },
        name: {
            type: String,
            default: 'file'
        },
        size: { // 2M
            type: Number,
            default: 2
        },
        limit: Number,
        accept: {
            type: String,
            default: 'png,jpeg'
        },
        params: Object,
        disabled: Boolean,
        onSuccess: Function,
        onError: Function
    },
    data () {
        return {
            imageUrl: this.value,
            loading: false
        }
    },
    watch: {
        value: {
            handler (nval) {
                this.imageUrl = nval
            },
            immediate: true
        }
    },
    computed: {
        imgAccept () {
            const types = this.accept.split(',') || []
            return types.map(p => !p.includes('imgae') ? `image/${p}` : p).join(',')
        }
    },
    methods: {
        // 限制判断
        doBeforeUpload (file) {
            this.loading = true
            const imgTypes = this.imgAccept.split(',') || []
            const isAccept = imgTypes.includes(file.type)
            const isLtM = file.size / 1024 / 1024 < this.size
            if (!isAccept) {
                this.loading = false
                this.$notify.error('Picture format error!')
            }
            if (!isLtM) {
                this.loading = false
                this.$notify.error(`Image size cannot be larger than ${this.size}M!`)
            }
            return isAccept && isLtM
        },
        // 新增成功
        handleOnSuccess (res, file) {
            this.loading = false
            if (res.code === 0) {
                this.imageUrl = res.data && res.data.url
                this.onSuccess && this.onSuccess({ res, file })
                this.$emit('input', res.data && res.data.url)
            } else {
                this.onError && this.onError({ res, file })
            }
        },
        // 上传错误
        handleOnError (err, file, fileList) {
            this.loading = false
            this.onError && this.onError({ err, file })
        }
    }
}
</script>

<style lang="scss" scoped>
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100%;
    height: 100%;
    line-height: 100%;
    text-align: center;
    border: 1px dashed $borderColor;
}
.avatar {
    width: 100%;
    height: 100%;
    display: block;
}
/deep/ .el-loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    margin-top: 0;
    height: 100%;
}
</style>
