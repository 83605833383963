import Vue from 'vue'
/* eslint-disable*/
// 根据币种类型返回符号
const filterCurrent = (val) => {
    const currents = {
        USD: '$',
        CNY: '￥'
    }
    return currents[val] || ''
}

const filters = {
    filterCurrent
}

Object.keys(filters).map(key => {
    Vue.filter(key, filters[key])
})
