<template>
    <transition name="el-fade-in">
        <div class="rap-image-preview" v-if="showPreview" @click.stop>
            <div class="rap-image-viewer__mask" v-if="mask"></div>
            <div class="rap-image-viewer__btn rap-image-viewer__close" @click="doClose"><i class="el-icon-close"></i></div>
            <div class="rap-image-viewer__btn rap-image-viewer__prev" @click="doViewer(-1)"><i class="el-icon-arrow-left"></i></div>
            <div class="rap-image-viewer__btn rap-image-viewer__next" @click="doViewer(1)"><i class="el-icon-arrow-right"></i></div>
            <div class="rap-image-viewer__btn rap-image-viewer__actions">
                <div class="rap-image-viewer__actions__inner">
                    <i class="el-icon-zoom-out" @click="doScale(-0.2)"></i>
                    <i class="el-icon-zoom-in" @click="doScale(0.2)"></i>
                    <i :class=" screen ? 'el-icon-c-scale-to-original' : 'el-icon-full-screen'" @click="doScreen"></i>
                    <i class="el-icon-refresh-left" @click="doRotate(-1)"></i>
                    <i class="el-icon-refresh-right" @click="doRotate(1)"></i>
                </div>
            </div>
            <div class="rap-image-viewer__canvas">
                <img :class="['rap-image-viewer__img', { 'screen': screen }]"
                    :src="previewUrl"
                    :style="`transform: scale(${Math.max(0.4, scale)}) rotate(${90 * rotate}deg);transition: all .1s;`">
                <div class="rap-image-viewer__lists" v-if="dataList.length">
                    <div v-for="imgUrl in dataList"
                        :key="imgUrl"
                        :class="['rap-image-card', { 'sel': previewUrl === imgUrl }]"
                        @click="doSelectImg(imgUrl)">
                        <img :src="imgUrl">
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        visible: Boolean,
        mask: {
            type: Boolean,
            default: true
        },
        dataList: Array
    },
    data () {
        return {
            previewUrl: '',
            showPreview: false,
            rotate: 0,
            scale: 1,
            screen: false
        }
    },
    watch: {
        visible: {
            handler (val) {
                this.showPreview = val
            },
            immediate: true
        },
        dataList: {
            handler (val) {
                this.previewUrl = val && val.length ? val[0] : ''
            },
            immediate: true
        }
    },
    methods: {
        // 关闭
        doClose () {
            this.$emit('update:visible', false)
            this.$emit('close')
        },
        // 列表点击
        doSelectImg (img) {
            this.rotate = 0
            this.scale = 1
            this.previewUrl = img
        },
        // 左右切换
        doViewer (index) {
            let curIndex = this.dataList.findIndex(p => p === this.previewUrl)
            curIndex += index
            if (curIndex >= this.dataList.length) {
                curIndex = 0
            } else if (curIndex < 0) {
                curIndex = this.dataList.length - 1
            }
            this.doSelectImg(this.dataList[curIndex])
        },
        // 旋转
        doRotate (index) {
            this.rotate += index
        },
        // 放大缩小
        doScale (step) {
            this.scale += step
        },
        // 全屏
        doScreen () {
            this.rotate = 0
            this.scale = 1
            this.screen = !this.screen
        }
    }
}
</script>

<style lang="scss" scoped>
.rap-image-preview {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4000;
    .rap-image-viewer__mask {
        width: 100%;
        height: 100%;
        background: rgba($color: #000000, $alpha: .4);
    }
    .rap-image-viewer__btn {
        position: absolute;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        opacity: .8;
        cursor: pointer;
        box-sizing: border-box;
        user-select: none;
        &.rap-image-viewer__close {
            color: white;
            top: 40px;
            right: 40px;
            width: 40px;
            height: 40px;
            font-size: 40px;
        }
        &.rap-image-viewer__prev,
        &.rap-image-viewer__next {
            top: 50%;
            transform: translateY(-50%);
            width: 44px;
            height: 44px;
            font-size: 24px;
            color: #000000;
            background-color: rgba($color: #ffffff, $alpha: .8);
            border-color: #fff;
            font-style: normal;
            font-weight: 400;
            font-variant: normal;
        }
        &.rap-image-viewer__prev {
            left: 40px;
        }
        &.rap-image-viewer__next {
            right: 40px;
        }
        &.rap-image-viewer__actions {
            left: 50%;
            bottom: 30px;
            transform: translateX(-50%);
            width: 282px;
            height: 44px;
            padding: 0 23px;
            z-index: 99;
            background-color: #606266;
            border-color: red;
            border-radius: 22px;
            .rap-image-viewer__actions__inner {
                width: 100%;
                height: 100%;
                text-align: justify;
                cursor: default;
                font-size: 23px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }
    }
    .rap-image-viewer__canvas {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .rap-image-viewer__img {
            max-height: calc(100vh - 240px);
            &.screen {
                max-width: 100%;
                max-height: 100%;
            }
            display: inline-block;
            background: chartreuse;
            z-index: 66;
        }
        .rap-image-viewer__lists {
            margin: 30px 0 80px 0;
            z-index: 88;
            width: 75%;
            overflow-x: auto;
            display: flex;
            justify-content: center;
            flex-direction: row;
            flex-shrink: 0;
            flex-grow: 0;
            .rap-image-card {
                width: 90px;
                height: 90px;
                border: 2px solid transparent;
                padding: 1px;
                border-radius: 2px;
                overflow: hidden;
                transition: all .3s ease-in-out;
                &.sel {
                    border-color: $mainPrimary;
                    transition: all .3s ease-in-out;
                }
                &:not(:last-of-type) {
                    margin-right: 10px;
                }
                img{
                    border-radius: 4px;
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}
</style>
