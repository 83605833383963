import Products from './products'
import UserCenter from './userCenter'
import ChannelCenter from './channel'
import OrderCenter from './order'

export default {
    ...Products,
    ...UserCenter,
    ...ChannelCenter,
    ...OrderCenter
}
