// import api from '@/api/index.js'
import {
    PRODUCT_BREADCRUMBS_ADD_TYPE,
    PRODUCT_BREADCRUMBS_POP_TYPE
} from '../mutation-types'

// 产品中心
const productCenter = {
    namespaced: true,
    state: {
        productBreadcrumbs: [],
        udImportList: {}, // 导入列表状态更新
        udAgainMaxCount: 5, // 导入列表状态更新次数
        udAgainTimer: null
    },
    getters: {
        getProductBreadcrumbs: (state, getters) => {
            return state.productBreadcrumbs
        }
    },
    mutations: {
        // 面包屑-新增
        [PRODUCT_BREADCRUMBS_ADD_TYPE] (state, data) {
            if (data.root) {
                state.productBreadcrumbs = []
            }
            if (data && !state.productBreadcrumbs.includes(data)) {
                state.productBreadcrumbs.push(data)
            }
        },
        [PRODUCT_BREADCRUMBS_POP_TYPE] (state, data) {
            state.productBreadcrumbs.pop()
        }
    },
    actions: {}
}
export default productCenter
