import http from '@/utils/http.js'

const baseUrl = process.env.VUE_APP_REQUEST_BASE_URL + process.env.VUE_APP_REQUEST_CHANNEL_CENTER // 代理关键字

// shopify店铺授权
const insertAccessToken = (data) => {
    return http.post(`${baseUrl}/ChannelCenter/insertAccessToken`, data)
}

// 是否绑定店铺
const isBindShop = (data) => {
    return http.get(`${baseUrl}/ChannelCenter/isBindShop`, data)
}

// 返回跳转shopify url
const getShopifyStoreUrl = (data) => {
    return http.get(`${baseUrl}/ChannelCenter/getSkipUrl`, data)
}

export default {
    insertAccessToken,
    isBindShop,
    getShopifyStoreUrl
}
