<template>
    <div class="rap-editor">
        <quill-editor class="editor"
            v-model="content"
            ref="myQuillEditor"
            :options="editorOption"
            @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)"
            @change="onEditorChange($event)">
        </quill-editor>
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// 工具栏配置
const toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
  ['blockquote', 'code-block'], // 引用  代码块
  [{ header: 1 }, { header: 2 }], // 1、2 级标题
  [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
  [{ script: 'sub' }, { script: 'super' }], // 上标/下标
  [{ indent: '-1' }, { indent: '+1' }], // 缩进
  [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
  [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
  [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
  [{ font: [] }], // 字体种类
  [{ align: [] }], // 对齐方式
  ['clean'] // 清除文本格式
//   ['link', 'image', 'video'] // 链接、图片、视频
]

export default {
    name: 'rap-editor',
    props: {
        value: String,
        maxSize: {
            type: Number,
            default: 4000 // kb
        },
        placeholder: {
            type: String,
            default: 'Please Input'
        }
    },
    components: {
        quillEditor
    },
    data () {
        return {
            content: this.value,
            editorOption: {
                placeholder: this.placeholder,
                theme: 'snow',
                modules: {
                    toolbar: {
                        container: toolbarOptions
                    }
                }
            }
        }
    },
    methods: {
        // 失去焦点事件
        onEditorBlur () {
            console.log('onEditorBlur')
            this.$emit('blur', this.content)
        },
        // 获得焦点事件
        onEditorFocus () {
            console.log('onEditorFocus')
            this.$emit('focus', this.content)
        },
        // 内容改变事件
        onEditorChange () {
            this.$emit('input', this.content)
        }
    }
}
</script>

<style lang="scss" scoped>
.rap-editor {
    .editor {
        line-height: normal !important;
        height: 266px;
        /deep/ .ql-container.ql-snow,
        /deep/ .ql-toolbar.ql-snow {
            border-color: $borderColor;
        }
        /deep/ .ql-container {
            height: calc(100% - 42px);
        }
    }
}
</style>
