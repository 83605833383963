<template>
    <!-- 菜单分类选择 -->
    <div class="rap-category__drawer">
        <transition name="el-zoom-in-top">
            <div class="rap-category__wrap"
                v-if="isShow">
                <div class="main-content-wrap flex-row" v-clickoutside="hide">
                    <div class="card-box" v-for="(item, index) in categoryLists" :key="index">
                        <div :class="['title', { 'sel': categoryId == item.catId }]" @click="handleSelect(item)">
                            {{ item.nameEn }}
                        </div>
                        <div class="list-wrap">
                            <div :class="['list-item', { 'sel': categoryId == sItem.catId }]"
                                v-for="(sItem, sIndex) in item.children"
                                :key="sIndex"
                                @click="handleSelect(sItem)">
                                <span>{{ sItem.nameEn }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import Clickoutside from 'element-ui/src/utils/clickoutside'

export default {
    name: 'rap-category-drawer',
    props: {
        visible: Boolean
    },
    directives: { Clickoutside },
    data () {
        return {
            isShow: true,
            categoryLists: [],
            categoryId: '',
            timer: null
        }
    },
    watch: {
        visible (nval, oval) {
            if (nval !== oval) {
                if (this.timer) {
                    clearTimeout(this.timer)
                    this.timer = setTimeout(() => {
                        this.isShow = nval
                    }, 100)
                }
            }
        },
        isShow (nval, oval) {
            if (nval !== oval) {
                this.$emit('update:visible', nval)
            }
        }
    },
    created () {
        this.reqAllCategorys()
    },
    methods: {
        // 请求所有分类
        reqAllCategorys () {
            this.$api.getCategoryTrees({

            }).then(res => {
                if (res.code === 0) {
                    this.categoryLists = (res.data || [])
                }
            })
        },
        handleSelect (data) {
            if (this.$route.name !== 'product' && this.$route.meta.menu !== 'product') {
                this.$router.push({
                    path: '/product/list'
                })
            }
            this.categoryId = data.catId
            this.$bus.$emit('ProductCategory', data.catId)
            this.$emit('update:visible', false)
        },
        hide () {
            this.$emit('update:visible', false)
        }
    }
}
</script>

<style lang="scss">
.rap-category__drawer{
    position: fixed;
    top: #{$headerHeight};
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    overflow: hidden;
    background: rgba($color: #000000, $alpha: 0.5);
    .rap-category__wrap{
        background: white;
        width: 100%;
        max-height: 340px;
        .flex-row{
            display: flex;
            flex-direction: row;
            padding:15px 0;
            .card-box {
                flex-grow: 1;
                flex-shrink: 0;
                min-width: 10%;
                &:last-of-type{
                    margin-right: 15px;
                }
            }
        }
        .title{
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 600;
            color: #000000;
            cursor: pointer;
            padding: 5px;
            &.sel{
                color: #FF6C23;
            }
        }
        .list-wrap{
            overflow-y: auto;
            // max-height: calc(100% - 54px);
            .list-item{
                cursor: pointer;
                padding: 8px 5px;
                font-size: 13px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                color: #1a1a1a;
                border-radius: 2px;
                transition: all .1s;
                &:hover{
                    background: rgba($color: #e7e7e7, $alpha: 0.5);
                    color: #FF6C23;
                    transition: all .3s;
                }
                &.sel{
                    color: #FF6C23;
                }
            }
        }
    }
}
</style>
