<template>
    <div class="popover-select__wrap">
        <rap-popover
            ref="popover"
            :placement="placement"
            :width="width"
            :trigger="trigger"
            :visibleArrow="true"
            :visible.sync="focus"
            transition="el-zoom-in-top"
            popper-class="popover-select">
            <!-- @mouseleave="hidePopover" -->
            <div slot="reference" class="label-wrap">
                {{ label }} <i :class="focus?'el-icon-caret-top':'el-icon-caret-bottom'"></i>
            </div>
            <div class="list-wrap">
                <div :class="['list-item', { 'select': inputVal == item.value, 'disabled': item.disabled }]"
                    v-for="(item, index) in dataList"
                    :key="index"
                    @click="handleSelectItem(item)">
                    <span class="label">{{ item.label }}</span>
                    <span v-if="inputVal == item.value"><i class="el-icon-check"></i></span>
                </div>
                <div class="no-data" v-if="dataList && !dataList.length"></div>
            </div>
        </rap-popover>
    </div>
</template>

<script>
export default {
    props: {
        value: [String, Number],
        label: String,
        placement: {
            type: String,
            default: 'bottom-end'
        },
        width: {
            type: Number,
            default: 200
        },
        trigger: {
            type: String,
            default: 'hover'
        },
        dataList: Array
    },
    data () {
        return {
            inputVal: this.value,
            focus: false
        }
    },
    watch: {
        value (nval, oval) {
            if (nval !== oval) {
                this.inputVal = nval
            }
        }
    },
    methods: {
        handleSelectItem (item) {
            if (!item.disabled) {
                let value = item.value
                if (this.inputVal === value) {
                    value = ''
                }
                this.hidePopover()
                this.$emit('input', value)
                this.$emit('change', value)
            }
        },
        hidePopover () {
            this.$refs.popover && this.$refs.popover.hide()
        }
    }
}
</script>

<style lang="scss">
.popover-select__wrap{
    display: flex;
    align-items: stretch;
    .label-wrap{
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 550;
        color: #1a1a1a;
        position: relative;
        padding: 2px 10px;
        max-height: 300px;
        // overflow: auto;
        &.up i {
            transform: rotate(180deg);
            transition: all .3s;
        }
    }
}
.popover-select{
    padding: 12px 0;
    .list-wrap{
        .list-item{
            cursor: pointer;
            box-sizing: border-box;
            width: 100%;
            padding: 0 15px;
            height: 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color: #1a1a1a;
            font-size: 14px;
            transition: all .3s;
            &.select{
                color: #ff6c23;
            }
            .label{
                font-family: PingFangSC, PingFangSC-Medium;
                font-weight: 500;
            }
            &:hover:not(.disabled) {
                background: #f7f7f7;
                transition: all .3s;
            }
            &.disabled {
                cursor: not-allowed;
                opacity: 0.7;
                background: #f7f7f7;
                transition: all .3s;
            }
        }
    }
}
</style>
