import api from '@/api/index.js'
import {
    UPDATE_USERINFO_TYPE,
    GET_USERINFO_TYPE,
    UPDATE_USER_SHOPIFYSTORE_TYPE,
    GET_USER_SHOPIFYSTORE_TYPE
} from '../mutation-types'

// 用户中心
const userCenter = {
    namespaced: true,
    state: {
        userInfo: null,
        bindShopifyStatus: 0
    },
    getters: {
        getUserInfo: (state, getters) => {
            return state.userInfo
        }
    },
    mutations: {
        [UPDATE_USERINFO_TYPE] (state, data) {
            state.userInfo = data
        },
        [UPDATE_USER_SHOPIFYSTORE_TYPE] (state, data) {
            state.bindShopifyStatus = data
        }
    },
    actions: {
        // 获取用户信息
        async [GET_USERINFO_TYPE] ({ commit }, callback) {
            try {
                const res = await api.getUserInfo({})
                if (res.code === 0) {
                    callback && callback(res.data)
                    commit('UPDATE_USERINFO_TYPE', res.data)
                }
            } catch (e) {
                console.log('Get UserInfo Failed!')
            }
        },
        // 获取绑定shopify状态
        async [GET_USER_SHOPIFYSTORE_TYPE] ({ commit, state }, callback) {
            let flag = 0
            if (state.bindShopifyStatus === 2) {
                flag = 2
                return callback && callback(flag)
            } else {
                try {
                    const res = await api.isBindShop({})
                    flag = 1
                    if (res.code === 0) {
                        flag = 2
                    }
                    commit('UPDATE_USER_SHOPIFYSTORE_TYPE', flag)
                    callback && callback(flag)
                } catch (e) {
                    console.log('Get UserInfo Failed!')
                    callback && callback(flag)
                }
            }
        }
    }
}
export default userCenter
