<template>
    <div class="footer-wrap">
        <div class="main-content-wrap">
            <div class="left">
                <rap-logo class="logo"></rap-logo>
                <div class="tips" style="margin-bottom: 9px;">
                    <span>{{ $t('rap.footer.label.title') }}</span>
                </div>
                <div class="tips">
                    <span>{{ $t('rap.footer.label.version') }}</span>
                </div>
            </div>
            <div class="right">
                <div class="link">
                    <el-link type="info">{{ $t('rap.footer.label.privacy-policy') }}</el-link>
                    <span class="margin">|</span>
                    <el-link type="info">{{ $t('rap.footer.label.terms-service') }}e</el-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RapLogo from './logo'
export default {
    components: {
        RapLogo
    }
}
</script>

<style lang="scss" scoped>
    .footer-wrap{
        width: 100%;
        height: $footerHeight;
        background: #f8f8f8;
        display: flex;
        justify-content: center;
        .main-content-wrap{
            height: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left, .right{
                height: 100%;
            }
            .right{
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                .link{
                    color: #e6e6e6;
                    margin-bottom: 24px;
                }
                .link .el-link{
                    opacity: 0.8;
                    font-size: 12px;
                    font-family: PingFangSC, PingFangSC-Regular;
                    font-weight: 400;
                    text-align: right;
                    color: #000000;
                }
                .margin{
                    margin: 6px;
                }
            }
            .logo{
                margin: 33px 0 18px 0;
            }
            .tips{
                color: #000000;
                line-height: 17px;
                opacity: 0.8;
                font-size: 12px;
                font-family: PingFangSC, PingFangSC-Regular;
                font-weight: 400;
                width: 530px;
            }
        }
    }
</style>
