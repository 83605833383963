import CommonCn from './common/cn'

export default {
    ...CommonCn,
    'rap.common.tip.email-empty': 'Please enter an email.',
    'rap.common.tip.email-fomat': 'Invalid email address!',
    'rap.common.tip.submit-success': 'Submitted successfully',
    // 首页
    'rap.home.label.first-title': 'Looking for high-quality suppliers directly, e-commerce business has never been so simple.',
    'rap.home.label.first-text': 'For you to connect with a large number of suppliers, provide high-quality and cheap Chinese goods, and provide unique services.',
    'rap.home.label.first-button': 'Access to distributor platform',
    // advantage
    'rap.home.label.advantage-title': 'Our Advantage',
    'rap.home.label.advantage-text': 'Help merchants make quick transactions and empower business growth',
    'rap.home.label.advantage-inventory-title': 'Inventory Management',
    'rap.home.label.advantage-inventory-text': 'Intelligent monitoring, comprehensive management',
    'rap.home.label.advantage-fast-title': 'Fast Delivery',
    'rap.home.label.advantage-fast-text': 'Quickly drop by one item and reach all over the world instantly',
    'rap.home.label.advantage-smart-title': 'Smart Warehouse',
    'rap.home.label.advantage-smart-text': 'Real-time data monitoring, intelligent replenishment of order system',
    'rap.home.label.advantage-sales-title': 'Sales Report',
    'rap.home.label.advantage-sales-text': 'Report display, bid farewell to tedious manual processing',
    'rap.home.label.advantage-featured-title': 'Featured Tags',
    'rap.home.label.advantage-featured-text': 'Customize high-quality labels to make your product unique',
    'rap.home.label.advantage-automatic-title': 'Automatic Trading',
    'rap.home.label.advantage-automatic-text': 'Big data monitoring, automatic order tracking and processing',
    // business
    'rap.home.label.business-title': 'Operation flow',
    'rap.home.label.business-text': 'Realize business closed-loop and empower cross-border sellers',
    'rap.home.label.business-title-01': 'Easily link your online store',
    'rap.home.label.business-text-01': 'Complete your order with one click on RapidDeals. RapidDeals can be easily synchronized with your online store, and all orders will be automatically displayed in your app.',
    'rap.home.label.business-title-02': 'Quickly find quality suppliers',
    'rap.home.label.business-text-02': 'Through the RapidDeals fast channel, quickly reach cooperation with a large number of suppliers, and quickly send the goods to all parts of the United States.',
    'rap.home.label.business-title-03': 'Orders can be shipped to all parts of the United States',
    'rap.home.label.business-text-03': 'You can enjoy fast transportation to various regions in the United States.',
    'rap.home.label.business-title-04': 'Support bulk ordering function',
    'rap.home.label.business-text-04': 'You can order products in bulk with just a few clicks.',
    // praise
    'rap.home.label.praise-title': 'High praise from RapidDeals users',
    'rap.home.label.praise-text': 'Help merchants make quick transactions and empower business growth',
    'rap.home.label.praise-username-01': 'RapidDeals Shopkeeper',
    'rap.home.label.praise-desc-01': 'Because I love e-sports, I started the business of e-sports. As a zero-based entrepreneur, I used RapidDeals to operate the service and built a very attractive and competitive e-sports merchandise store. At the same time, I used the agent promotion service to help me do marketing, and soon ushered in the first order. I am now full of confidence in my e-commerce business!',
    'rap.home.label.praise-username-02': 'Catalina',
    'rap.home.label.praise-desc-02': 'RapidDeals is a very good platform. After being recommended and used by my friends, I like it after using it. It has brought me very considerable benefits. I like RapidDeals very much and I will recommend it to my friends. There are many It has brought me very considerable benefits. IIt has brought me very considerable benefits. Igreat functions inside, such as data report viewing, one-click import orders and other functions.',
    'rap.home.label.praise-username-03': 'Stark Tony',
    'rap.home.label.praise-desc-03': 'The function is fully compatible with Amazon sellers, the professional and efficient service team is obtained in real-time for multi-store data, the interface is simple and easy to use, and the quick browser can be accessed at any time to support independent deployment, 5 minutes of customer service quick response, consultant and technical support, full tracking, free training and full process service support.',
    // contact us
    'rap.contactUs.banner.title': 'Contact us',
    'rap.contactUs.banner.desc': 'We will solve all your problems and meet your business requirements',
    'rap.contactUs.submit.your-name': 'Your Name',
    'rap.contactUs.submit.your-last-name': 'Your Last Name',
    'rap.contactUs.submit.email': 'Email',
    'rap.contactUs.submit.infomation': 'Infomation',
    'rap.contactUs.tip.your-name-empty': 'Your Name cannot be empty',
    'rap.contactUs.tip.your-last-name-empty': 'Your Last Name cannot be empty',
    'rap.contactUs.tip.infomation-empty': 'Infomation cannot be empty',
    'rap.contactUs.info.question-01': 'What shipping carriers do you use?',
    'rap.contactUs.info.question-02': 'How long will it take to get my order?',
    'rap.contactUs.info.question-03': 'Where are your products sent from?',
    'rap.contactUs.info.question-04': 'How Much Does Shipping Cost?',
    'rap.contactUs.info.mobile': '(628) 900-9001',
    'rap.contactUs.info.email': 'Hello@rapiddeals.com',
    'rap.contactUs.info.address': '155 N RIVERVIEW DR ANAHEIM HILLS CA 92808',
    // help-center
    'rap.helpCenter.banner.title': 'RapidDeals Help center',
    'rap.helpCenter.content.question-01': `Why we${"'"}re better than AliExpress?`,
    'rap.helpCenter.content.answer-01': `No need! One of RapidDeals${"'"} algorithms is to allow you to maintain zero inventory. Once you have completed the sale, RapidDeals will process the order information for you.`,
    'rap.helpCenter.content.question-02': 'Do I have to buy inventory ahead of time?',
    'rap.helpCenter.content.answer-02': 'You do not! One of the best parts of the dropshipping model is that you need to hold ZERO inventory. Once you make a sale, RapidDeals handles 100% of the product fulfillment for you.',
    'rap.helpCenter.content.question-03': 'How can I customize my packaging?',
    'rap.helpCenter.content.answer-03': 'We have the ability to customize any part of your delivery package, even including your product and private-labeling any products.',
    'rap.helpCenter.content.question-04': 'Can I test products before I sell them on my store?',
    'rap.helpCenter.content.answer-04': 'Yes! You can get a test product before you begin to sell the product. It’s a unique function to the RapidDeals platform, you can get a test order on the Product Page.',
    'rap.helpCenter.content.question-05': 'Do I have to manually enter all of my orders?',
    'rap.helpCenter.content.answer-05': 'Of course not! Unlike our competitors, we have auto-fulfillment so you never have to worry about fulfillment after you sell.',
    'rap.helpCenter.content.question-06': 'How does tracking work?',
    'rap.helpCenter.content.answer-06': `Once an order is placed on RapidDeals, as long as you fulfill it, a tracking code will be uploaded to the product as soon as it is processed. This tracking number will also be given to Shopify via our API so that your customers are notified of their tracking number when it is available. As long as you haven${"'"}t changed the default shipping notification settings in Shopify, your customers will receive those confirmation emails like clockwork.`,
    'rap.helpCenter.content.question-07': 'Can I integrate with any platform other than Shopify?',
    'rap.helpCenter.content.answer-07': 'Currently, RapidDeals only integrates with Shopify. Our tech team is working on integrations with other platforms like WooCommerce, Magento, GrooveKart, etc. Stay tuned for updates on new fe.',
    // footer
    'rap.footer.label.title': 'RapidDeals provides millions of products for your e-commerce store and connects you to a supplier market with just a few mouse clicks.',
    'rap.footer.label.version': '@2019-2020 www.RapidDeals.com',
    'rap.footer.label.privacy-policy': 'Privacy Policy',
    'rap.footer.label.terms-service': 'Terms of Service'
}
