export default [{
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('@/views/rap/dashboard/dashboard.vue')
  },
  {
    path: '/product',
    name: 'product',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('@/views/rap/product/index.vue'),
    children: [{
        path: '',
        name: 'productList',
        meta: {
          needLogin: true,
          menu: '/product/list',
          menuLogin: true
        },
        component: () => import('@/views/rap/product/list/product-list.vue')
      },
      {
        path: 'list',
        name: 'productList',
        meta: {
          needLogin: true,
          menu: '/product/list',
          menuLogin: true
        },
        component: () => import('@/views/rap/product/list/product-list.vue')
      },
      {
        path: 'detail/:skuId/:spuId',
        name: 'productDetail',
        meta: {
          needLogin: true,
          menu: '/product/list',
          menuLogin: true
        },
        component: () => import('@/views/rap/product/detail/product-detail.vue')
      },
      {
        path: 'addComment/:skuId/:spuId',
        name: 'addComment',
        meta: {
          needLogin: true,
          menu: '/product/list',
          menuLogin: true
        },
        component: () => import('@/views/rap/product/comment/add-comment.vue')
      }
    ]
  },
  {
    path: '/importList',
    name: 'importList',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('@/views/rap/importList/import-list.vue')
  },
  {
    path: '/myProduct',
    name: 'myProduct',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('@/views/rap/myProduct/my-product.vue')
  },
  {
    path: '/personal',
    name: 'personal',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('@/views/rap/personal/index.vue'),
    children: [{
        path: '',
        name: 'baseInfo',
        meta: {
          needLogin: true,
          menu: '/personal',
          menuLogin: true
        },
        component: () => import('@/views/rap/personal/base/base-info.vue')
      },
      {
        path: 'baseInfo',
        name: 'baseInfo',
        meta: {
          needLogin: true,
          menu: '/personal',
          menuLogin: true
        },
        component: () => import('@/views/rap/personal/base/base-info.vue')
      },
      {
        path: 'address',
        name: 'address',
        meta: {
          needLogin: true,
          menu: '/personal',
          menuLogin: true
        },
        component: () => import('@/views/rap/personal/address/address.vue')
      }
    ]
  },
  {
    path: '/order',
    name: 'order',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('@/views/rap/orders/index.vue'),
        children: [{
        path: 'shopify',
        name: 'shopify',
        meta: {
          needLogin: true,
          menu: '/order/shopify',
          menuLogin: true
        },
        component: () => import('@/views/rap/orders/shopify/shopify-order.vue')
      },
      {
        path: 'paynow',
        name: 'paynow',
        meta: {
          needLogin: true,
          menu: '/order/shopify',
          menuLogin: true
        },
        component: () => import('@/views/rap/orders/paynow/paynow-order.vue')
      },
      {
        path: 'batch',
        name: 'batch',
        meta: {
          needLogin: true,
          menu: '/order/shopify',
          menuLogin: true
        },
        component: () => import('@/views/rap/orders/batch/batch-order.vue')
      }
    ]
  },
  {
    path: '/mycard',
    name: 'mycard',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('@/views/rap/shopCard/mycard.vue')
  },
  {
    path: '/Paymentsuccessful',
    name: 'Paymentsuccessful',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('@/views/rap/Paymentsuccessful/Paymentsuccessful.vue')

  },
  {
    path: '/ShoppingCartEmpty',
    name: 'ShoppingCartEmpty',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('@/views/rap/ShoppingCartEmpty/ShoppingCartEmpty.vue')
  },
  {
    path: '/Payment_information',
    name: 'Payment_information',
    meta: {
      needLogin: true,
      menuLogin: true
    },
    component: () => import('../views/rap/PaymentInformation/Payment_information.vue')
  }
]
