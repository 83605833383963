import http from '@/utils/http.js'

const baseUrl = process.env.VUE_APP_REQUEST_BASE_URL + process.env.VUE_APP_REQUEST_PRODUCT_CENTER // 代理关键字

// 获取所有品牌
const getAllBrands = (data) => {
    return http.get(`${baseUrl}/pmsbrand/brands`, data)
}

// 获取热门品牌
const getHotBrands = (data) => {
    return http.get(`${baseUrl}/pmsbrand/hotBrand`, data)
}

// 所有供应商
const getAllSuppliers = (data) => {
    return http.get(`${baseUrl}/pmssupplier/suppliers`, data)
}

// 获取一级分类
const getCategoryLevelOne = (data) => {
    return http.get(`${baseUrl}/pmscategory/categoryLevelOne`, data)
}

// 获取所有分类(树形)
const getCategoryTrees = (data) => {
    return http.get(`${baseUrl}/pmscategory/categoryTrees`, data)
}

// 商品搜索
const searchProducts = (data) => {
    return http.get(`${baseUrl}/pmsspuinfo/searchProducts`, data)
}

// 导入列表
const importShopifyList = (data) => {
    return http.post(`${baseUrl}/pmsspuinfo/importList`, data)
}

// 删除-import list
const removeShopifyList = (data) => {
    return http.remove(`${baseUrl}/pmsspuinfo/importList`, data)
}
// 删除-我的产品
const removeMyProducts = (data) => {
    return http.remove(`${baseUrl}/pmsspuinfo/myProducts`, data)
}

// 获取商品详情
const getProductInfo = (data) => {
    return http.get(`${baseUrl}/pmssellsku/productInfo`, data)
}

// 获取评论列表
const getCommentLists = (data) => {
    return http.get(`${baseUrl}/pmscomment/comments`, data)
}

// 添加评论
const addComment = (data) => {
    return http.post(`${baseUrl}/pmscomment/comment`, data)
}

// 判断用户是否存在分类偏好
const checkMemberCategory = (data) => {
    return http.get(`${baseUrl}/pmscategory/checkMemberCategory`, data)
}

// 添加用户分类偏好
const addMemberCategory = (data) => {
    return http.post(`${baseUrl}/pmscategory/memberCategory`, data)
}

// import list列表
const getImportList = (data) => {
    return http.get(`${baseUrl}/pmsspuinfo/importList`, data)
}

// 更新列表
const updateImportData = (data) => {
    return http.put(`${baseUrl}/pmsspuinfo/importList`, data)
}

// 推送列表
const pushImportList = (data) => {
    return http.post(`${baseUrl}/pmsspuinfo/pushImportList`, data)
}

// 推送状态
const queryPushStatus = (data) => {
    return http.post(`${baseUrl}/pmsspuinfo/queryPushStatus`, data)
}

// 我的产品
const getMyProducts = (data) => {
    return http.get(`${baseUrl}/pmsspuinfo/myProducts`, data)
}

export default {
    getAllBrands,
    getHotBrands,
    getAllSuppliers,
    getCategoryLevelOne,
    getCategoryTrees,
    searchProducts,
    importShopifyList,
    removeShopifyList,
    getProductInfo,
    getCommentLists,
    checkMemberCategory,
    addMemberCategory,
    addComment,
    getImportList,
    updateImportData,
    pushImportList,
    queryPushStatus,
    getMyProducts,
    removeMyProducts
}
