<template>
    <div class="rap-image">
        <el-image :src="src"
            :fit="fit"
            :alt="alt"
            :z-index="zIndex"
            :lazy="lazy"
            v-loading="isLoading"
            @load="load"
            @error="error"
            :scroll-container="scrollContainer">
            <div slot="placeholder">
                <slot name="placeholder"></slot>
            </div>
            <div slot="error">
                <slot name="error"></slot>
            </div>
        </el-image>
        <img class="default-placeholder"
            v-if="isDefault && (defaultImg || defaultImage)"
            :src="defaultImg || defaultImage" alt="">
    </div>
</template>

<script>
export default {
    props: {
        src: String,
        defaultImg: String,
        fit: {
            type: String,
            default: 'cover'
        },
        alt: String,
        lazy: {
            type: Boolean,
            default: false
        },
        zIndex: Number,
        scrollContainer: {
            type: String,
            default: 'app'
        },
        type: { // 1是商品 2是头像
            type: [String, Number],
            default: 1
        },
        loading: Boolean
    },
    data () {
        return {
            default: {
                1: require('@/assets/default-image.svg'),
                2: require('@/assets/rap/default-avator.svg')
            },
            isLoading: false,
            isDefault: true
        }
    },
    computed: {
        defaultImage () {
            return this.default[this.type] || this.default[1]
        }
    },
    watch: {
        src: {
            handler (val) {
                if (this.loading) {
                    this.isLoading = !!val
                }
            },
            immediate: true
        }
    },
    methods: {
        load () {
            this.isDefault = false
            if (this.loading) {
                this.isLoading = false
            }
            this.$emit('load', this.src)
        },
        error () {
            this.isDefault = true
            this.$emit('error', this.src)
        }
    }
}
</script>

<style lang="scss" scoped>
.rap-image {
    width: 100%;
    height: 100%;
    font-size: 100%;
    position: relative;
    background: #FAFAFA;
    /deep/ .el-image {
        width: 100%;
        height: 100%;
    }
    .default-placeholder {
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        transform: translateY(-50%);
        max-width: 40%;
    }
}
</style>
