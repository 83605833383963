<template>
    <div class="rap-error">
        <rap-image class="error-img" :src="imgUrl || defaultImg"></rap-image>
        <div class="title-wrap">
            <p class="title" v-if="title">{{ title }}</p>
            <p class="question">{{ question || `Fortunately, there's an easy solution.` }}</p>
        </div>
        <div class="rap-btn-group">
            <slot v-if="$slots.button" name="button"></slot>
            <el-button v-else-if="btnTitle" size="''" type="primary" @click="doJump">{{ btnTitle }}</el-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'rap-error',
    props: {
        imgUrl: String,
        title: String,
        question: String,
        btnTitle: String,
        btnPath: String
    },
    data () {
        return {
            defaultImg: require('@/assets/no-data-empty.svg')
        }
    },
    methods: {
        // 跳转
        doJump () {
            if (this.btnPath) {
                this.$router.push({
                    path: this.btnPath
                })
            } else {
                this.$emit('btnClick')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.rap-error {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 100px 50px;
    box-sizing: border-box;
    background: white;
    .error-img {
        width: 108px;
        margin-bottom: 30px;
    }
    .title {
        font-size: 17px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        color: #999999;
        margin-bottom: 10px;
        text-align: center;
    }
    .question {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        color: #1a1a1a;
        text-align: center;
    }
    .rap-btn-group {
        margin-top: 40px;
    }
}
</style>
