<template>
    <div class="rap-select"
        @click="doFocus">
        <div :class="['rap-select__label', { 'focus': focus || (inputVal && inputVal.length) }]" v-if="label">{{ label }}</div>
        <el-select ref="select"
            v-model="inputVal"
            :placeholder="placeholder"
            :multiple="multiple"
            :multiple-limit="multipleLimit"
            :collapse-tags="collapseTags"
            @change="handleChange"
            @blur="handleBlur"
            @focus="handleFocus">
            <template v-if="group">
                <el-option-group v-for="(item, index) in lists"
                    :key="index"
                    :label="item[labelKey]"
                    :value="item[valueKey]">
                    <el-option v-for="(child, cIndex) in item.children"
                        :key="cIndex"
                        :label="child[labelKey]"
                        :value="child[valueKey]">
                    </el-option>
                </el-option-group>
            </template>
            <template v-else>
                <el-option v-for="(item, index) in lists"
                    :key="index"
                    :label="item[labelKey]"
                    :value="item[valueKey]">
                </el-option>
            </template>
        </el-select>
    </div>
</template>

<script>
export default {
    props: {
        value: [String, Number, Array],
        placeholder: {
            type: String,
            default: ' '
        },
        lists: {
            type: Array,
            default: () => []
        },
        multiple: Boolean,
        multipleLimit: Number,
        group: Boolean,
        label: String,
        labelKey: {
            type: String,
            default: 'label'
        },
        valueKey: {
            type: String,
            default: 'value'
        },
        clearable: Boolean,
        disabled: Boolean,
        collapseTags: Boolean
    },
    data () {
        return {
            focus: false,
            inputVal: this.value
        }
    },
    watch: {
        value (nval, oval) {
            if (nval !== oval) {
                this.inputVal = nval
            }
        }
    },
    methods: {
        handleChange () {
            this.$emit('input', this.inputVal)
            this.$emit('change', this.inputVal)
        },
        handleInput (val) {
            this.$emit('input', val)
        },
        doFocus (val) {
            if (this.disabled) return
            this.focus = !this.focus
            if (this.focus) {
                this.$refs.select.focus()
            } else {
                this.$refs.select.blur()
            }
        },
        handleBlur () {
            this.focus = false
        },
        handleFocus () {
            this.focus = true
        }
    }
}
</script>

<style lang="scss" scoped>
.rap-select{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    .rap-select__label{
        position: absolute;
        top: 8px;
        left: 5px;
        height: 20px;
        line-height: 20px;
        z-index: 3;
        padding: 0 3px;
        background: transparent;
        transition: all .15s ease-in-out;
        color: #1d1d1d;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        &.focus {
            background: white;
            transform: translateY(-22px) scale(0.9);
            transition: all .20s ease-in-out;
        }
    }
    .el-select {
        width: 100%;
    }
    /deep/ .el-select__tags {
        .el-tag {
            border-width: 0;
            border-radius: 12px;
            padding: 0 10px;
            transition: all .15s ease-in-out;
            margin-right: 5px;
            margin-left: 0;
            &:first-of-type {
                margin-left: 5px;
            }
            &.el-tag--info .el-tag__close {
                right: -2px;
                background: #c7c7c7;
                color: white;
                transition: all .15s ease-in-out;
                &:hover {
                    background: #FF4D4F;
                    color: white;
                    transition: all .3s ease-in-out;
                }
            }
            &:not(:last-of-type) {
                margin-right: 5px;
            }
        }
    }
}
</style>
