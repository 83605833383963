import Vue from 'vue'
import VueRouter from 'vue-router'
import RapRoutes from './rap'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('@/views/home/home.vue')
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            nolayout: true
        },
        component: () => import('@/views/login/login.vue')
    },
    {
        path: '/link-account',
        name: 'linkAccount',
        meta: {
            nolayout: true
        },
        component: () => import('@/views/linkAccount/linkAccount.vue')
    },
    {
        path: '/help-center',
        name: 'helpCenter',
        component: () => import('@/views/helpCenter/helpCenter.vue')
    },
    {
        path: '/contact-us',
        name: 'contactUs',
        component: () => import('@/views/contact/contactUs.vue')
    },
    ...RapRoutes
]

// 解决问题
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
    // 指定该钩子函数，返回坐标值即可
    // scrollBehavior (to, from, savedPosition) {
    //     return { x: 0, y: 0 }
    // }
})

// 登录校验 + 重定向
const vm = new Vue()
router.beforeEach((to, from, next) => {
    const token = vm.$cookie.get('USER_TOKEN')
    if (to.fullPath === '/') {
        if (token) {
            next({ path: '/product/list' })
        } else {
            next({ path: '/home' })
        }
    } else if (to.meta.needLogin) {
        if (token) {
            if (to.query.redirect) {
                next({ path: to.query.redirect })
            }
        } else {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    } else if (to.path === '/login') {
        if (token) {
            if (to.query.redirect) {
                next({ path: to.query.redirect })
            } else {
                next({ path: '/product/list' })
            }
        }
    }
    next()
})

export default router
