
import Vue from 'vue'
import pagination from '@/components/pagination/pagination.vue'
import popover from '@/components/popover/popover.vue'
import popoverSelect from '@/components/popover-select/popover-select.vue'
import rapImage from '@/components/rap-image/rap-image.vue'
import rapImagePreview from '@/components/rap-image/rap-image-preview.vue'
import rapSelect from '@/components/rap-select/rap-select.vue'
import rapEditor from '@/components/rap-editor/rap-editor.vue'
import rapInput from '@/components/rap-input/rap-input.vue'
import rapPriceInput from '@/components/rap-input/rap-price-input.vue'
import rapTagInput from '@/components/rap-input/rap-tag-input.vue'
import rapError from '@/components/rap-error/rap-error.vue'
import rapUpload from '@/components/rap-upload/rap-upload.vue'
import rapRate from '@/components/rap-rate/rap-rate.vue'

Vue.component('rap-pagination', pagination)
Vue.component('rap-popover', popover)
Vue.component('rap-popover-select', popoverSelect)
Vue.component('rap-image', rapImage)
Vue.component('rap-select', rapSelect)
Vue.component('rap-editor', rapEditor)
Vue.component('rap-input', rapInput)
Vue.component('rap-price-input', rapPriceInput)
Vue.component('rap-tag-input', rapTagInput)
Vue.component('rap-error', rapError)
Vue.component('rap-upload', rapUpload)
Vue.component('rap-rate', rapRate)
Vue.component('rap-image-preview', rapImagePreview)
