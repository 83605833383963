// http://momentjs.cn/ 方法
import Moment from 'moment'

const lang = localStorage.getItem('LANG') === 'zh_CN' ? 'zh-cn' : 'en'
Moment.locale(lang)

const format = (date, format = 'MMMM D, YYYY') => {
    if (!date) {
        return ''
    }
    return Moment(date).format(format)
}

export default {
    format
}
