import http from '@/utils/http.js'

const baseUrl = process.env.VUE_APP_REQUEST_BASE_URL + process.env.VUE_APP_REQUEST_ORDER_CENTER // 代理关键字

// 添加购物车
const addCart = (data) => {
    return http.post(`${baseUrl}/omsCart/cart`, data)
}

// 获取购物车列表
const getCartList = (data) => {
    return http.get(`${baseUrl}/omsCart/cart`, data)
}

export default {
    addCart,
    getCartList
}
