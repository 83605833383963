<template>
    <div class="layout-wrap">
        <rap-header></rap-header>
        <div class="main-content">
            <el-main>
                <router-view></router-view>
            </el-main>
            <rap-footer></rap-footer>
        </div>
    </div>
</template>

<script>
    import RapFooter from './footer.vue'
    import RapHeader from './header.vue'

    export default {
        components: {
            RapFooter,
            RapHeader
        },
        data () {
            return {
                unLoginMenus: []
            }
        }
    }
</script>

<style lang="scss" scoped>
    .layout-wrap{
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        .main-content{
            padding-top: $headerHeight;
        }
        .el-main{
            min-height: calc(#{$mainHeight} - #{$headerHeight} - #{$footerHeight});
            padding: 0;
            overflow: hidden;
        }
    }
</style>
