import Cookie from 'js-cookie'

const set = (key, value, options) => {
    Cookie.set(key, value, options)
}

const get = (key, options) => {
    return Cookie.get(key, options)
}

const remove = (key) => {
    Cookie.remove(key, { path: '/' })
}

/**
 * token
 */
const setToken = (key, value, days) => {
    set(key, value, { expires: days, path: '/' })
}

export default {
    set,
    get,
    remove,
    setToken
}
