
const dispatch = (that, componentName, eventName, params) => {
    var parent = that.$parent || that.$root
    var name = parent.$options.componentName
    while (parent && (!name || name !== componentName)) {
        parent = parent.$parent
        if (parent) {
            name = parent.$options.componentName
        }
    }
    if (parent) {
        parent.$emit.apply(parent, [eventName].concat(params))
    }
}

export default dispatch
