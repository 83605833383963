<template>
    <div class="rap-input__wrap"
        :class="{ 'is-focus': focus }"
        @click="doFocus">
        <div :class="['label-wrap', { 'focus': this.focus || this.inputVal }]" v-if="label">{{ label }}</div>
        <el-input v-model="inputVal"
            ref="input"
            :placeholder="placeholder"
            :clearable="clearable"
            @input="handleInput"
            @change="handleChange"
            @blur="handleBlur">
        </el-input>
    </div>
</template>

<script>
export default {
    props: {
        value: [String, Number],
        label: String,
        placeholder: String,
        clearable: Boolean,
        maxlength: Number,
        minlength: Number,
        showPassword: String,
        disabled: String
    },
    data () {
        return {
            focus: false,
            inputVal: this.value
        }
    },
    watch: {
        value (nval, oval) {
            if (nval !== oval) {
                this.inputVal = nval
            }
        }
    },
    methods: {
        handleInput (val) {
            this.$emit('input', val)
        },
        handleChange (val) {
            this.$emit('change', val)
        },
        doFocus (val) {
            if (this.disabled) return
            this.focus = !this.focus
            if (this.focus) {
                this.$refs.input.focus()
            } else {
                this.$refs.input.blur()
            }
        },
        handleBlur () {
            this.focus = false
            this.$emit('blur')
        }
    }
}
</script>

<style lang="scss" scoped>
.rap-input__wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #DCDFE6;
    border-radius: 2px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    &.is-focus {
        border-color: #ff6c23;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .label-wrap {
        position: absolute;
        top: 8px;
        left: 5px;
        height: 20px;
        line-height: 20px;
        z-index: 3;
        padding: 0 3px;
        background: white;
        transition: all .15s ease-in-out;
        color: #1d1d1d;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: 500;
        &.focus {
            transform: translateY(-22px) scale(0.9);
            transition: all .20s ease-in-out;
        }
    }
    .tags-wrap {
        flex-grow: 1;
        flex-shrink: 0;
        padding: 0 0 0 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-end;
        max-width: 70%;
        overflow-x: auto;
        .el-tag {
            &:not(:last-of-type) {
                margin-right: 5px;
            }
        }
    }
    /deep/ .el-input__inner {
        border-width: 0;
    }
}
</style>
