<template>
    <el-popover
        ref="elPopover"
        v-model="isVisible"
        :placement="placement"
        :width="width"
        :trigger="trigger"
        :popper-class="popperClass"
        :visible-arrow="visibleArrow"
        :transition="transition"
        :offset="offset">
        <slot></slot>
        <div slot="reference">
            <slot name="reference"></slot>
        </div>
    </el-popover>
</template>

<script>
export default {
    props: {
        visible: Boolean,
        placement: {
            type: String,
            default: 'bottom-end'
        },
        width: {
            type: Number,
            default: 200
        },
        trigger: {
            type: String,
            default: 'hover'
        },
        popperClass: String,
        visibleArrow: {
            type: Boolean,
            default: true
        },
        transition: String,
        offset: Number
    },
    data () {
        return {
            isVisible: false
        }
    },
    watch: {
        visible (nval, oval) {
             if (nval !== oval) {
                 this.isVisible = nval
             }
        },
        isVisible (nval, oval) {
            if (nval !== oval) {
                this.$emit('update:visible', nval)
            }
        }
    },
    methods: {
        show () {
            this.$refs.elPopover && this.$refs.elPopover.doShow()
        },
        hide () {
            this.$refs.elPopover && this.$refs.elPopover.doClose()
        }
    }
}
</script>
