<template>
    <div class="menu-list">
        <el-menu mode="horizontal"
            :default-active="routePath"
            @select="handleSelect">
            <!-- 一级导航 -->
            <template v-for="menu in dataList">
                <el-menu-item v-if="(!menu.subMenus || !menu.subMenus.length)"
                    :key="menu.id"
                    :index="`${menu.path}`">
                    <template v-if="menu.component">
                        <el-popover
                            v-model="menu.visible"
                            placement="bottom"
                            trigger="hover"
                            transition="el-fade-in"
                            popper-class="drawer-popover"
                            :visible-arrow="false">
                            <div slot="reference">{{ menu.name }} <i :class="['el-icon-arrow-down arrow', { 'up': menu.visible }]"></i></div>
                            <component :is="menu.component" :visible.sync="menu.visible"></component>
                        </el-popover>
                    </template>
                    <template v-else>
                        {{ menu.name }}
                    </template>
                </el-menu-item>
                <!-- 二级导航 -->
                <el-submenu v-else
                    popper-class="nav-menus-popover"
                    :key="menu.id"
                    :index="`${menu.path}`">
                    <template slot="title">{{ menu.name }}</template>
                    <template v-for="subMenu in menu.subMenus">
                        <el-menu-item v-if="!subMenu.subMenus || !subMenu.subMenus.length"
                            :key="subMenu.id"
                            :index="`${subMenu.path}`">
                            {{ subMenu.name }}
                        </el-menu-item>
                        <el-submenu v-else
                            :key="subMenu.id"
                            :index="`${subMenu.path}`">
                            <template slot="title">{{ subMenu.name }}</template>
                        </el-submenu>
                    </template>
                </el-submenu>
            </template>
        </el-menu>
    </div>
</template>

<script>
export default {
    props: {
        dataList: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            activeMenu: ''
        }
    },
    computed: {
        routePath () {
            return this.activeMenu || this.$route.meta.menu || this.$route.path
        }
    },
    methods: {
        handleSelect (key, keyPath) {
            // 首页特殊处理
            this.activeMenu = null
            if (key.split('/').includes('home')) {
                let anchorId = 'firstAnchorId'
                if (key.split('/').includes('flow')) {
                    this.activeMenu = '/home/flow'
                    anchorId = 'opertionFlowAnchorId'
                }
                key = '/home'
                this.$nextTick(() => {
                    document.getElementById(anchorId) && document.getElementById(anchorId).scrollIntoView()
                })
            }
            this.$router.push({ path: key })
        }
    }
}
</script>

<style lang="scss" scoped>
    .menu-list{
        /deep/ .el-submenu .el-submenu__title,
        /deep/ .el-menu .el-menu-item {
            line-height: $headerHeight;
            height: $headerHeight;
        }
        /deep/ .el-menu,
        /deep/ .el-submenu .el-submenu__title{
            border-bottom-width: 0;
            background-color: transparent;
        }
        /deep/ .el-menu-item,
        /deep/ .el-submenu,
        /deep/ .el-submenu .el-submenu__title{
            padding: 0;
            margin: 0 20px;
            font-size: 14px;
            font-family: PingFangSC, PingFangSC-Medium;
            font-weight: 700;
            color: #1a1a1a;
            background: transparent !important;
            & > .el-submenu__title {
                margin: 0;
            }
            &.is-active, &:hover{
                .el-submenu__title, i {
                    color: $mainPrimary !important;
                }
                color: $mainPrimary !important;
            }
        }
        .arrow{
            font-size: 12px;
            width: 16px;
            transition: all .2s;
            color: #909399;
            &.up {
                transform: rotate(180deg);
                transition: transform .3s;
            }
        }
    }
</style>
<style lang="scss">
.drawer-popover {
    width: 0 !important;
    padding: 0 !important;
}
.nav-menus-popover {
    /deep/ .el-menu {
        margin-top: 1px;
        .el-menu-item {
            color: #1a1a1a !important;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            &.is-active {
                color: $mainPrimary !important;
            }
        }
    }
}
</style>
